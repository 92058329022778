// dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { ButtonBase, makeStyles, useTheme } from '@material-ui/core'
import { graphql } from 'gatsby'
import classNames from 'classnames'
// components
import ApplyButton, { isSynchronyApplyLink, isSynchronyWithQueriesLink } from '@shared/apply-button'
import { getSynchronyLink } from '@helpers/finance'
import { ButtonLink } from './Link'

const useStyles = makeStyles(theme => ({
  button: ({
    padding,
    backgroundColor,
    borderColor,
    borderRadius,
    borderWidth,
    flexDirection,
    color,
    backgroundColorHover,
    fontSize,
    margin,
    isBannerButton,
    textColorHover,
    mosaicGridBackgroundColorHover,
    mosaicGridTextColorHover,
  }) => ({
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection,
    height: 'auto',
    margin,
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: mosaicGridBackgroundColorHover || backgroundColorHover,
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      ...(mosaicGridTextColorHover || textColorHover ? { color: mosaicGridTextColorHover || textColorHover } : {}),
    },
    '&:focus': {
      borderRadius: 0,
      outline: 'none',
    },
    padding,
    borderRadius: borderRadius || 0,
    backgroundColor: backgroundColor || 'transparent',
    textDecoration: 'none',
    textAlign: 'center',
    borderColor: borderColor || backgroundColor,
    borderWidth: isBannerButton ? borderWidth || '0px' : '1px',
    borderStyle: 'solid',
    color: color || theme.palette.primary.main,
    fontSize: fontSize || '1.25rem',
    fontWeight: 500,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.short,
    }),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
    '& span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
    },
  }),
  buttonIcon: {
    maxHeight: '8rem',
    width: 'auto',
    objectFit: 'cover',
    padding: '4px',
  },
}))

const position = {
  left: 'row',
  top: 'column',
  right: 'row-reverse',
}

const StrapiButton = ({ data, className = '', styles = {}, trackingData = null, isBannerButton = false }) => {
  const theme = useTheme()
  const buttonStyles = {
    backgroundColor: data?.BackgroundColor?.ColorHex || theme.palette.primary.main,
    backgroundColorHover: data?.BackgroundColorHover?.ColorHex || theme.palette.primary.dark,
    borderColor: data?.BorderColor?.ColorHex || null,
    borderRadius: `${data?.BorderRadius || 4}px`,
    color: data?.TextColor?.ColorHex || theme.palette.common.white,
    textColorHover: data?.TextColorHover?.ColorHex || null,
    flexDirection: position[data?.IconPosition || 'left'],
    isBannerButton,
    fontSize: `1rem`,
    padding: '8px 16px',
    ...styles,
  }
  const classes = useStyles(buttonStyles)
  const linkData = data?.Link?.Link
  const linkProps = data?.Link?.Link ? { component: ButtonLink, data: data.Link, trackingData } : {}

  if (linkData?.ExternalUrl && isSynchronyApplyLink(linkData?.ExternalUrl)) {
    return (
      <ApplyButton
        key={linkData.id}
        url={
          isSynchronyWithQueriesLink(linkData?.ExternalUrl)
            ? `${getSynchronyLink()}${linkData?.ExternalUrl.substring(
                linkData?.ExternalUrl.indexOf('&'),
              )}&modalapply=true`
            : `${getSynchronyLink()}&modalapply=true`
        }
        aria-label={linkData?.Title}
        action="link click"
        className={classNames(classes.button, className)}
        data-testid={linkData?.testId}
      >
        {data?.DisplayText && <span>{data?.Text || data?.Title}</span>}
      </ApplyButton>
    )
  }

  return data?.FinanceButton ? (
    <ApplyButton
      asButton
      url={linkProps.data?.Link?.ExternalUrl}
      category="credit-card"
      action="apply-now"
      // label="cart"
      title={linkProps.data?.Link?.Title}
      className={classNames(classes.button, className)}
    >
      {data?.DisplayText && <span>{data?.Text || data?.Title}</span>}
    </ApplyButton>
  ) : (
    <ButtonBase data-testid={data?.testId} className={classNames(classes.button, className)} {...linkProps}>
      {data?.DisplayIcon && data?.Icon?.url && (
        <img
          className={classes.buttonIcon}
          src={`${data.Icon.url}&fm=webp`}
          alt={data.Icon?.alternativeText || data?.Text || data?.Title}
        />
      )}
      {data?.DisplayText && <span>{data?.Text || data?.Title}</span>}
    </ButtonBase>
  )
}

StrapiButton.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  styles: PropTypes.object,
  trackingData: PropTypes.object,
  isBannerButton: PropTypes.bool,
}

export default StrapiButton

export const StrapiButtonFragment = graphql`
  fragment StrapiButtonFragment on StrapiButton {
    __typename
    id
    testId
    Title
    Text
    IconPosition
    DisplayIcon
    DisplayText
    DisplayBorder
    TextColor {
      ColorHex
    }
    TextColorHover {
      ColorHex
    }
    BorderColor {
      ColorHex
    }
    BackgroundColor {
      ColorHex
    }
    BackgroundColorHover {
      ColorHex
    }
    BorderRadius
    Icon {
      ...StrapiImageFragment
    }
    Link {
      Link {
        ...StrapiLinkFragment
      }
    }
  }
`
