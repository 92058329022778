import React from 'react'
import ApplyButton from '@shared/apply-button'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { getSynchronyLink } from '@helpers/finance'

const useStyles = makeStyles(theme => ({
  applyButton: {
    margin: '1rem 0.2rem',
    fontSize: '1rem !important',
    lineHeight: '1rem',
    width: 'auto',
  },
  learnButton: {
    textDecoration: 'underline',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  html: {
    padding: theme.spacing(2),
  },
}))

const BannerFinanceButtons = ({ data }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <ApplyButton
        url={getSynchronyLink()}
        rel="nofollow"
        category="credit-card"
        action="apply-now"
        className={`button apply-button ${classes.applyButton}`}
        label="banner"
        value="Apply now"
        style={{
          backgroundColor: data.ApplyNowBackgroundColor?.ColorHex,
          color: data.ApplyNowFontColor?.ColorHex,
        }}
      >
        Apply Now
      </ApplyButton>
      <div>
        <button
          type="button"
          className={`learn-more ${classes.learnButton} synchrony-prequal-learn-more`}
          gtm-category="credit-card"
          gtm-action="learn-more"
          gtm-label="banner"
          style={{ color: data.LearnMoreLinkColor?.ColorHex }}
        >
          Learn More
        </button>
      </div>
    </div>
  )
}

BannerFinanceButtons.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BannerFinanceButtons
